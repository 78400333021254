/**
 * @file
 * Styles for misc stuff
 */

@import "variables";


//
// Pager
//

.item-list:first-of-type .pager {
  margin: 0 0 15px;
}
.item-list:last-of-type .pager {
  margin: 45px 0 0;
}

.item-list .pager {

  display: inline-block;
  width: 100%;

  // Next & Prev
  
  li.pager-next, li.pager-previous, li.pager-first, li.pager-last {
    padding: 0;
    margin: 0;
    a {
      padding: 9px 10px;
      background: #eee;
      @include border-radius(2px);
      font-weight: bold;
      color: $orange;
      display: inline-block;
      line-height: 1em;
      &:hover {
        background: $orange;
        text-decoration: none;
        color: white;
      }
    }
  }

  // 1,2,3 ...

  li {
    &.pager-item {
      padding: 0;
      margin: 0 0 0 5px;
      font-weight: bold;
      a {
        padding: 5px 10px;
        background: none;
        @include border-radius(2px);
        color: $orange;
        display: inline-block;
        margin: 0 0 10px;
        &:hover {
          background: $orange;
          text-decoration: none;
          color: white;
        }
      }
    }
    &.pager-current {
      margin: 0 0 0 5px;
      padding: 8px 10px;
      @include border-radius(2px);
      background: $orange;
      color: white;
    }
  }
}

//
// Results per page option
//

.choose-per-page {
  text-align: right;
  margin-bottom: 15px;

  label {
    margin-right: .5em;
  }

  * {
    display: inline-block;
  }
}

//
// Live Chat
//

#LiveHelpCallAction {
  display: none !important; // Hide "Chat with us here"
}
